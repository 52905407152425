import $ from "jquery";

$(document).on('turbolinks:load', () => {
  const form = $("#admin_provider_form_container");
  if (form.length > 0) {
    const latestDmProviderType = form[0].dataset.latestDmProviderType.toLowerCase();
    const alertDiv = $("#dni_type_mismatch_alert");

    const mexicoFields = $('#mexico_fields');
    const colombiaFields = $('#colombia_fields');
    const countrySelect = $('.country-select');
    const facturacionSection = $('#facturacion_section');
    const dniTypeSelect = $('#provider_business_id_type');

    const updateDniTypes = (countryId, currentValue) => {
      const dniTypesByCountry = {
        '142': [
          { value: 'curp', text: 'Clave Única de Registro de Población (CURP)' },
          { value: 'rfc', text: 'Registro Federal de Contribuyentes (RFC)' },
          { value: 'cp', text: 'Cédula Profesional (CP)' }
        ],
        '65': [
          { value: 'ruc', text: 'Registro Único de Contribuyente (RUC)' }
        ],
        '50': [
          { value: 'nit', text: 'Número de Identificación Fiscal (NIT)' },
          { value: 'cc', text: 'Cédula de Ciudadanía (CC)' },
          { value: 'ce', text: 'Cédula de Extranjería (CE)' },
          { value: 'pe', text: 'Permiso especial de Permanencia (PEP)' },
          { value: 'ppt', text: 'Permiso por Protección Temporal (PPT)' },
          { value: 'nuip', text: 'Número Único de Identificación Personal (NUIP)' },
          { value: 'ruc', text: 'Registro Único de Contribuyente (RUC)' }
        ],
        'default': [
          { value: 'rut', text: 'Rol único tributario (RUT)' }
        ]
      };

      const selectedDniTypes = dniTypesByCountry[countryId] || dniTypesByCountry['default'];
      dniTypeSelect.empty();
      selectedDniTypes.forEach((type) => {
        dniTypeSelect.append(new Option(type.text, type.value));
      });

      // Establecer el valor seleccionado si currentValue está definido, de lo contrario seleccionar la primera opción
      if (currentValue && selectedDniTypes.some(type => type.value === currentValue)) {
        dniTypeSelect.val(currentValue);
      } else {
        dniTypeSelect.val(selectedDniTypes[0]?.value); // Seleccionar la primera opción
      }
    };

    const toggleCountryFields = () => {
      const selectedCountryId = countrySelect.val();
      const currentDniValue = dniTypeSelect.val(); // Obtener el valor actual del selector
      updateDniTypes(selectedCountryId, currentDniValue);
      if (selectedCountryId === '142') {
        facturacionSection.show();
        mexicoFields.show();
        colombiaFields.hide();
        // Habilitar campos obligatorios para México
        mexicoFields.find('input, select').prop('required', true);
        colombiaFields.find('input, select').prop('required', false);
      } else if (selectedCountryId === '50') { // Colombia
        facturacionSection.show();
        mexicoFields.hide();
        colombiaFields.show();
        // Habilitar campos obligatorios para Colombia
        colombiaFields.find('input, select').prop('required', true);
        mexicoFields.find('input, select').prop('required', false);
      } else {
        // Si no es México ni Colombia, ocultar toda la sección de facturación
        facturacionSection.hide();
        mexicoFields.find('input, select').prop('required', false);
        colombiaFields.find('input, select').prop('required', false);
      }
    };

    updateDniTypes(countrySelect.val(), dniTypeSelect.data('current-value'));
    toggleCountryFields();

    // Escuchar cambios en el selector de país
    countrySelect.on('change', function() {
      toggleCountryFields();
    });

    const checkTypeMismatch = () => {
      let currentType;
      // Verificar si estamos en el edit (campo de entrada)
      if ($("#provider_business_id_type").length > 0) {
        currentType = $("#provider_business_id_type").val().trim().toLowerCase();
      } else {
        // En el show, obtenemos el texto del elemento
        currentType = $("#business_id_type").text().trim().toLowerCase();
      }

      if (currentType === "" || currentType !== latestDmProviderType) {
        alertDiv.show();
      } else {
        alertDiv.hide();
      }
    };

    checkTypeMismatch();

    // Solo agregar el evento si estamos en el edit
    if ($("#provider_business_id_type").is("input, select")) {
      $("#provider_business_id_type").on("input change", () => {
        checkTypeMismatch();
      });
    }
  }

  if ($(".admin-benefits-info-container").length > 0){
    const toggleSwitch = (checkbox) => {

      const field = checkbox.dataset.field;
      const updateBlock = checkbox.dataset.updateBlock;
    
      const data = {};
      data[field] = checkbox.checked;
    
      const form = $("#edit_full_translation_form");
      const url = form.data('url');
    
      $.ajax({
          url: url,
          type: 'PATCH',
          data: { provider: data },
          dataType: 'json',
          success: (response) => {
              if (updateBlock) {
                  $(`#${updateBlock}`).html(response.credits_status_html);
              }
          },
          error: (xhr) => {
              const errors = xhr.responseJSON ? xhr.responseJSON.errors : ['Error desconocido'];
              console.error('Error al actualizar el campo:', errors);
          }
      });
    };
    

    $(document).on('change', '.switch input[type="checkbox"]', function() {
      toggleSwitch(this);
    });
  }

  const providerShowPage = $(".admin-show-provider");

  if (providerShowPage.length > 0) {
    
    const gertyCodeElement = providerShowPage.find("#gerty_code_text");
    const gertyCodeWarningIcon = providerShowPage.find("#gerty_code_warning");
    const gertyCodeValue = gertyCodeElement.text().trim();

    const validateGertyCode = (gertyCode) => {
      if (gertyCode.length !== 81) {
        showGertyCodeWarning(false, 'Tú código gerty debe tener exactamente 81 caracteres');
      } else {
        validateWithDentalink(gertyCode);
      }
    };

    const validateWithDentalink = (gertyCode) => {
      $.ajax({
        type: "POST",
        url: "/wizard/connect/validate_gerty_code",
        data: { gerty_code: gertyCode },
        success: function(response) {
          if (response.valid) {
            showGertyCodeWarning(true, 'Tú código gerty es válido');
          } else {
            showGertyCodeWarning(false, 'Tú código gerty es inválido');
          }
        },
        error: function() {
          showGertyCodeWarning(false, 'Error al validar el código gerty con Dentalink');
        }
      });
    };

    const showGertyCodeWarning = (isValid, message) => {
      if (isValid) {
        gertyCodeWarningIcon
          .removeClass("fa fa-exclamation-triangle")
          .addClass("fa fa-plug")
          .css('color', 'green')
          .attr('title', message)
          .tooltip('dispose')
          .tooltip();
      } else {
        gertyCodeWarningIcon
          .removeClass("fa fa fa-plug")
          .addClass("fa fa-exclamation-triangle")
          .css('color', 'red')
          .attr('title', message)
          .tooltip('dispose')
          .tooltip();
      }
      gertyCodeWarningIcon.show();
    };

    validateGertyCode(gertyCodeValue);

    $('[data-toggle="tooltip"]').tooltip();
  }
});
